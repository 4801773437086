import React from "react";
import * as classes from "./index.module.less";
import Layout from "components/shared/Layout";
import Card from "components/shared/Card";
import fingerprint from "images/svg_icons/fingerprint.svg";
import exam from "images/svg_icons/exam.svg";

export default () => (
  <div className={classes.container}>
    <Layout>
      <h3>Our Integration Solutions</h3>
      <div className={classes.cards}>
        <Card
          title="SDK Analysis & Resolution"
          align="verticalreverse"
          content={
            <p>
              Upload your Android APK or iOS app through the client dashboard
              and we will scan it for issues preventing your app or game from
              working in China.
            </p>
          }
          img={exam}
        />
        <Card
          title="Comprehensive Testing"
          href="/services/testing/"
          align="verticalreverse"
          content={
            <p>
              As you localize and fine tune your app or game for the Chinese
              market, our team will conduct on-site usability tests for real
              user feedback.
            </p>
          }
          img={fingerprint}
        />
      </div>
    </Layout>
  </div>
);
