import React from "react";
import Meta from "components/shared/Meta";
import Nav from "components/shared/Nav";
import Footer from "components/shared/Footer";
import CTA from "components/shared/CTA";
import LargeTitle from "components/services/LargeTitle";
import icon from "images/svg_icons/integration.svg";
import { StaticImage } from "gatsby-plugin-image";
import ImageBlock from "components/services/ImageBlock";
import IntegrationGrid from "components/services/IntegrationGrid";

export default () => (
  <>
    <Meta url="/services/integration/" />
    <Nav />
    <LargeTitle
      img={icon}
      green="Integration"
      black="Services"
      text="The Great Firewall of China is not insurmountable. Our team knows the best way to get your app or game working just as well in China as it does everywhere else."
    />
    <ImageBlock
      title="Work within the Great Firewall"
      text={
        <>
          <p>
            Because many common Google Services are blocked in China, some SDKs
            and other dependencies within your app or game may not function
            properly on the Mainland.
          </p>
          <p>
            Never fear: We will find alternatives for your developers, and test
            your app relentlessly to ensure you can reach all users with no
          </p>
        </>
      }
      img={
        <StaticImage
          src="../../images/photos/wall-close.jpg"
          alt="Great Wall"
          placeholder="blurred"
          layout="fixed"
          width={800}
          height={650}
        />
      }
    />
    <IntegrationGrid />
    <CTA />
    <Footer />
  </>
);
